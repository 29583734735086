<template>
    <div class="content">
        <el-card style="height: 18rem;">
            <div slot="header" class="clearfix">
                    <span class="header_title">定时 备份/清理</span>
            </div>
            <div class="item" style="justify-content:space-around;">
                <div class="content">
                    <el-form :model="backupForm" label-width="100px" size='medium' ref="backupForm" :rules="rules">
                        <el-form-item label="备份时间" prop="sys_backup_time">
                            <el-time-picker v-model="backupForm.sys_backup_time" style="width: 100%;" value-format='HH:mm' format='HH:mm'></el-time-picker>
                        </el-form-item>
                        <el-form-item label="备份频率">
                            <template>
                                <div style="text-align: left;margin-bottom: 5px;">
                                    <el-radio v-model="backupForm.sys_backup_frequency" :label="1">每一天</el-radio>
                                    <el-radio v-model="backupForm.sys_backup_frequency" :label="2">每二天</el-radio>
                                    <el-radio v-model="backupForm.sys_backup_frequency" :label="3">每三天</el-radio>
                                </div>
                                <div class="item a">
                                    <el-radio v-model="backupForm.sys_backup_frequency" :label="4">每一周</el-radio>
                                    <el-select v-model="backupForm.sys_backup_day" placeholder="请选择" :disabled="backupForm.sys_backup_frequency===4?false:true">
                                        <el-option label="星期一" :value="1"></el-option>
                                        <el-option label="星期二" :value="2"></el-option>
                                        <el-option label="星期三" :value="3"></el-option>
                                        <el-option label="星期四" :value="4"></el-option>
                                        <el-option label="星期五" :value="5"></el-option>
                                        <el-option label="星期六" :value="6"></el-option>
                                        <el-option label="星期日" :value="7"></el-option>
                                    </el-select>
                                </div>
                                <div class="item a">
                                    <el-radio v-model="backupForm.sys_backup_frequency" :label="5">每个月</el-radio>
                                    <div>
                                        <el-input-number v-model="backupForm.sys_backup_day" :min="1" :max="31" controls-position="right" :disabled="backupForm.sys_backup_frequency===5?false:true"></el-input-number>
                                        <span> 号</span>
                                    </div>
                                </div>
                            </template>
                        </el-form-item>
                    </el-form>
                </div>
                

                <div class="box"></div>

                <div class="content">
                    <el-form :model="clearForm" label-width="80px" size='medium' ref="clearForm" :rules="rules">
                        <el-form-item label="清理" >
                            <div class="item" style="text-align: left;margin-top: 10px;">
                                <el-radio v-model="clearForm.sys_clear_frequency" :label="1">一周前</el-radio>
                                <el-radio v-model="clearForm.sys_clear_frequency" :label="2">一月前</el-radio>
                                <el-radio v-model="clearForm.sys_clear_frequency" :label="3">二月前</el-radio>
                                <el-radio v-model="clearForm.sys_clear_frequency" :label="4">三月前</el-radio>
                                <el-radio v-model="clearForm.sys_clear_frequency" :label="5">半年前</el-radio>
                                <el-radio v-model="clearForm.sys_clear_frequency" :label="6">一年前</el-radio>
                            </div>
                        </el-form-item>
                        <el-form-item label="清理类型">
                            <div class="item">
                                <el-checkbox v-model="clearForm.sys_clear_type1">进出图片</el-checkbox>
                                <el-checkbox v-model="clearForm.sys_clear_type2">数据库备份</el-checkbox>
                            </div>
                        </el-form-item>
                        <el-form-item label="在场记录">
                            <div class="item">
                                <span>超出&nbsp;</span>
                                <el-input-number v-model="clearForm.sys_clear_inlog_frequency" :min="0" controls-position="right" style="width: 150px;"></el-input-number>
                                <span>&nbsp;天的自动清理</span>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-card>

        <div class="item" style="width: 100%;height: 21rem;">
            <el-card style="width: 50%;">
                <div slot="header" class="clearfix">
                    <span class="header_title">立即清理</span>
                </div>
                <div class="item" style="justify-content:space-around;">
                    <div class="content">
                        <el-form :model="nowclearForm" label-width="100px" size='medium'>
                            <el-form-item label="清理时间">
                                <template>
                                    <div style="text-align: left;">
                                        <el-radio v-model="nowclearForm.now_clear_time" :label="1">一个月前</el-radio>
                                        <el-radio v-model="nowclearForm.now_clear_time" :label="2">二个月前</el-radio>
                                        <el-radio v-model="nowclearForm.now_clear_time" :label="3">三个月前</el-radio>
                                        <el-radio v-model="nowclearForm.now_clear_time" :label="4">半年前</el-radio>
                                        <el-radio v-model="nowclearForm.now_clear_time" :label="5">一年前</el-radio>
                                    </div>
                                </template>
                            </el-form-item>
                            <el-form-item label="清理类型">
                                <div class="item" style="justify-content: space-between;">
                                    <div class="item">
                                        <el-checkbox v-model="nowclearForm.now_clear_type1">进出图片</el-checkbox>
                                        <el-checkbox v-model="nowclearForm.now_clear_type2">数据库备份</el-checkbox>
                                    </div>
                                    <el-button type="primary" size="small" @click="clear(0)">立即清理</el-button>
                                </div>
                            </el-form-item>
                            <el-form-item label="进出记录">
                                <div class="item" style="justify-content: space-between;">
                                    <el-date-picker v-model="nowclearForm.now_inlog_time" type="datetimerange" range-separator="--" start-placeholder="起始日期" end-placeholder="结束日期" style="width: 100%;" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                                    <el-button style="margin-left: 5px;" type="primary" size="small" @click="clear(1)">立即清理</el-button>
                                </div>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </el-card>


            <el-card style="width: 50%; margin-left: 10px;">
                <div slot="header" class="clearfix">
                    <span class="header_title">窗口</span>
                </div>
                <div class="content">
                    <el-form :model="showForm" label-width="120px" size='medium' ref="showForm" :rules="rules">
                        <el-form-item label="Led显示时长" prop="sys_ledshow_timelen">
                            <div class="item a">
                                <el-input-number v-model="showForm.sys_ledshow_timelen" :min="1" controls-position="right"></el-input-number>
                                <span>&nbsp;  秒</span>
                            </div>
                        </el-form-item>
                        <el-form-item label="弹窗关闭时长" prop="sys_close_form_timelen">
                            <div class="item a">
                                <el-input-number v-model="showForm.sys_close_form_timelen" :min="1" controls-position="right"></el-input-number>
                                <span>&nbsp; 秒</span>
                            </div>
                        </el-form-item>
                        <el-form-item label="小票">
                            <div class="item">
                                <el-checkbox v-model="showForm.sys_is_print" style="margin-right: 10px;">打印小票</el-checkbox>
                                <div class="content">
                                    <div class="item a">
                                        <span style="width: 80px;">标题头：</span>
                                        <el-input :disabled="!showForm.sys_is_print" v-model="showForm.sys_print_title"></el-input>
                                    </div>
                                    <div class="item a"> 
                                        <span style="width: 80px;">标题尾</span>
                                        <el-input :disabled="!showForm.sys_is_print" v-model="showForm.sys_print_end"></el-input>
                                    </div>
                                </div>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </el-card>
        </div>

        <div>
            <el-button type="primary" size="medium" @click="submit" style="width: 80px;">保存</el-button>
        </div>
        
    </div>
</template>

<script>
import util from '../../util/util'

export default{
    data(){
        return{
            backupForm:{
                sys_id:0,
                sys_backup_time:'00:00',
                sys_backup_frequency:4,
                sys_backup_day:1,
            },
            clearForm:{
                sys_clear_frequency:4,
                sys_clear_type1:true,
                sys_clear_type2:true,
                sys_clear_inlog_frequency:30,
            },
            showForm:{
                sys_ledshow_timelen:20,
                sys_close_form_timelen:60,
                sys_is_print:false,
                sys_print_title:'',
                sys_print_end:'',
            },
            nowclearForm:{
                opt_type:0,
                now_clear_time:3,
                now_clear_type1:true,
                now_clear_type2:true,
                now_inlog_time:[]
            },
            rules: {
                sys_backup_time: [{ required: true, message: '备份时间禁止为空', trigger: 'blur' }],
                sys_ledshow_timelen:[{ required: true, message: 'Led显示时长禁止为空', trigger: 'blur' }],
                sys_close_form_timelen:[{ required: true, message: '弹窗关闭时长禁止为空', trigger: 'blur' }],
            },
        }
    },
    created(){
        this.getData()
    },
    methods:{
        getData(){
            util.Get('sysconfig/getdata').then(res=>{
                if(res.rpStatus===10000){
                    if(res.list.length>0){
                        this.backupForm.sys_id=res.list[0].sys_id
                        this.backupForm.sys_backup_time=res.list[0].sys_backup_time
                        this.backupForm.sys_backup_frequency=res.list[0].sys_backup_frequency
                        this.backupForm.sys_backup_day=res.list[0].sys_backup_day

                        this.clearForm.sys_clear_frequency=res.list[0].sys_clear_frequency
                        var state= res.list[0].sys_clear_type
                        if(state&0x01===0x01){
                            this.clearForm.sys_clear_type1=true
                        }
                        if(state&0x02===0x02){
                            this.clearForm.sys_clear_type2=true
                        }
                        this.clearForm.sys_clear_inlog_frequency=res.list[0].sys_clear_inlog_frequency

                        this.showForm.sys_ledshow_timelen=res.list[0].sys_ledshow_timelen
                        this.showForm.sys_close_form_timelen=res.list[0].sys_close_form_timelen
                        this.showForm.sys_is_print=res.list[0].sys_is_print
                        this.showForm.sys_print_title=res.list[0].sys_print_title
                        this.showForm.sys_print_end=res.list[0].sys_print_end
                    }
                    else{
                        this.backupForm.sys_backup_time='00:00'
                        this.backupForm.sys_backup_frequency=4
                        this.backupForm.sys_backup_day=1

                        this.clearForm.sys_clear_frequency=4
                        this.clearForm.sys_clear_type1=true
                        this.clearForm.sys_clear_type2=true
                        this.clearForm.sys_clear_inlog_frequency=30

                        this.showForm.sys_ledshow_timelen=20
                        this.showForm.sys_close_form_timelen=30
                        this.showForm.sys_is_print=false
                        this.showForm.sys_print_title=''
                        this.showForm.sys_print_end=''
                    }
                }
            })
        },
        submit(){
            if(util.CheckUserAuth('5-3-3')){
                var b1=false
                this.$refs.backupForm.validate((vaild)=>{if(vaild){b1=true}})
                var b2=false
                this.$refs.clearForm.validate((vaild)=>{if(vaild){b2=true}})
                var b3=false
                this.$refs.showForm.validate((vaild)=>{if(vaild){b3=true}})

                if(b1&&b3&&b2){
                    var scForm=Object.assign({},this.backupForm,this.clearForm,this.showForm)
                    scForm.sys_clear_type=0
                    if(scForm.sys_clear_type1){
                        scForm.sys_clear_type+=1
                    }
                    if(scForm.sys_clear_type2){
                        scForm.sys_clear_type+=2
                    }


                    if(scForm.sys_backup_frequency===5&&!scForm.sys_backup_day){
                        this.$message.error("备份日期禁止为空");
                        return
                    }
                    if(scForm.sys_backup_frequency===4&&scForm.sys_backup_day>7){
                        this.$message.error("请选择备份星期");
                        return
                    }
                    if(scForm.sys_clear_type===0){
                        this.$message.error("请选择清理类型");
                        return
                    }
                    util.Post('sysconfig/edit',scForm).then(res=>{
                        if(res.rpStatus===10000){
                            this.$message.success('设置成功')
                        }else{
                            this.$message.error('设置失败')
                        }
                    })
                }
            }
        },
        clear(optType){
        if((optType===1&& util.CheckUserAuth('5-3-1'))||(optType===0&& util.CheckUserAuth('5-3-2'))){
            if(optType==0&&!this.nowclearForm.now_clear_type1&&!this.nowclearForm.now_clear_type2){
                this.$message.error("请选择清理类型")
                return
            }
            if(optType==1&&(!this.nowclearForm.now_inlog_time||(this.nowclearForm.now_inlog_time&&this.nowclearForm.now_inlog_time.length===0))){
                this.$message.error("请选择清理日期")
                return
            }

            var txt='确定清理'
           this.nowclearForm.opt_type=optType
           var clearType=0
           if(this.nowclearForm.now_clear_type1){
               txt+='“进出图片”'
               clearType+=1
           }
           if(this.nowclearForm.now_clear_type2){
                txt+=((clearType==1?'与':'')+'“数据库备份”')
               clearType+=2
           }
           this.nowclearForm.now_clear_type=clearType

           
           this.nowclearForm.now_clearlog_begintime=''
           this.nowclearForm.now_clearlog_endtime=''
           if(this.nowclearForm.now_inlog_time&&this.nowclearForm.now_inlog_time.length>0){
                this.nowclearForm.now_clearlog_begintime=this.nowclearForm.now_inlog_time[0]
                this.nowclearForm.now_clearlog_endtime=this.nowclearForm.now_inlog_time[1]
           }


           if(optType===1){
               txt="确定清理该日期内进出记录？"
            }

           this.$confirm(txt, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                util.Post('sysconfig/clearnow',this.nowclearForm).then(res=>{
                    if(res.rpStatus===10000){
                        this.$message.success('清理成功')
                    }
                    else{
                        this.$message.error(res.rpMsg)
                    }
                })
            }).catch(e => e)
        }
       }
    }
}
</script>

<style scoped>
.content{
      max-height: calc(100vh - 85px);
    height: calc(100vh - 85px); 
}


.el-card{
    margin-bottom: 10px;
}
.header_title{
    color: white;
    font-size: 14px;
}

::v-deep .el-card__header{
    background-color:darkgrey;
    height: 50px;
}

.item{
    display: flex;
    flex-direction: row;
}

.a{
    margin-bottom: 15px;
    align-items: center;
}

.box{
    border-right: 1px solid #DEE3E7;
}

.content{
    display: flex;
    flex-direction: column;
}

</style>